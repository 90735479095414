import { assert } from '@core/assert'
import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'
import httpClient from './httpClient'

export class map extends BaseService {
  static get entity() {
    return 'map'
  }
  static async getContractorById(Id) {
    try {
      const response = await httpClient.get(`${this.entity}/findbyid/${Id}`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  static async getSites() {
    try {
      const response = await httpClient.get(`${this.entity}/getsites`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  
}

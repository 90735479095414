<template>
  <section>
    <div>
      <MapComponent
        :markerControl="true"
        :draggable="false"
        :sites="siteMaps()"
        :geoControl="true"
        :regions="regionMaps()"
        :sidebarControl="true"
        @showSiteModal="handleOpenSiteModal"
      >
      </MapComponent>
    </div>
    <div class="bottom col-md-6">
      <b-card class="collapse-icon" no-body>
        <b-card-header v-if="menuCollapse.isCollapsed">
          <b-button block variant="primary" @click="expandMe()">
            <i class="fas fa-plus"></i>
            Add Site
          </b-button>
        </b-card-header>
        <b-card-body>
          <div>
            <transition name="slide">
              <div
                v-if="menuCollapse.isCollapsed"
                :style="menuCollapse.style"
                ref="example"
                :key="menuCollapse.collapseID"
              >
                <perfect-scrollbar>
                  <!-- types -->
                  <app-collapse>
                    <app-collapse-item
                      v-for="region in allRegionGroups"
                      :key="region.name"
                      :isVisible="true"
                      :title="region.name"
                    >
                      <b-list-group>
                        <b-card
                          class="border shadow mb-1"
                          no-body
                          @mouseover="handleHoverEffectStart(site.siteID)"
                          @mouseout="handleHoverEffectStop(site.siteID)"
                          @click="handleFlyToMarker(site)"
                          v-for="site in region.sites"
                          :key="site._id"
                          :id="'siteListItem-' + site.siteID"
                        >
                          <b-card-header class="mt-2">
                            <b-card-title align="center">{{
                              site.name
                            }}</b-card-title>
                          </b-card-header>
                          <b-container>
                            <b-card-body class="statistics-body">
                              <b-row>
                                <b-col align="center">
                                  <b-media no-body>
                                    <b-media-aside>
                                      <b-avatar
                                        size="48"
                                        variant="danger"
                                        :badge="site.cameras.length.toString()"
                                        align="center"
                                        :disabled="!(site.cameras.length > 0)"
                                      >
                                        <feather-icon
                                          size="24"
                                          icon="CameraIcon"
                                        />
                                      </b-avatar>
                                    </b-media-aside>
                                    <b-media-body class="my-auto">
                                      <p>Cameras</p>
                                    </b-media-body>
                                  </b-media>
                                </b-col>
                                <b-col align="center">
                                  <b-media no-body>
                                    <b-media-aside>
                                      <b-avatar
                                        size="48"
                                        variant="success"
                                        :badge="site.rdps.length.toString()"
                                        align="center"
                                      >
                                        <feather-icon
                                          size="24"
                                          icon="MonitorIcon"
                                        />
                                      </b-avatar>
                                    </b-media-aside>
                                    <b-media-body class="my-auto">
                                      <p>RDPs</p>
                                    </b-media-body>
                                  </b-media>
                                </b-col>
                                <b-col align="center">
                                  <b-media no-body>
                                    <b-media-aside>
                                      <b-avatar
                                        size="48"
                                        :badge="site.rdps.length.toString()"
                                        variant="warning"
                                        align="center"
                                      >
                                        <feather-icon
                                          size="24"
                                          icon="BookmarkIcon"
                                        />
                                      </b-avatar>
                                    </b-media-aside>
                                    <b-media-body class="my-auto">
                                      <p>QuickLinks</p>
                                    </b-media-body>
                                  </b-media>
                                </b-col>
                              </b-row>
                            </b-card-body>
                          </b-container>
                        </b-card>
                      </b-list-group>
                    </app-collapse-item>
                  </app-collapse>
                </perfect-scrollbar>
              </div>
            </transition>
          </div>
          <div class="row">
            <div class="col-md-6 mr-auto">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  placeholder="Search"
                  v-model="searchTerm"
                  id="search"
                  @keyup="search()"
                  type="text"
                />
              </b-input-group>
            </div>
            <div class="col-auto">
              <b-button
                variant="flat-primary"
                class="btn-icon"
                @click="menuCollapse.isCollapsed = !menuCollapse.isCollapsed"
              >
                <feather-icon icon="Maximize2Icon" />
              </b-button>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </div>

    <!-- Site Modal Popup -->
    <b-modal
      id="site-modal"
      size="lg"
      hide-footer
      centered
      @hidden="clearModal()"
    >
      <template #modal-title>
        {{ selectedSite.name }} | {{ selectedSite.lsd }}
      </template>
      <div class="d-block row text-center">
        <div class="mb-1">
          <b-button
            class="mr-1"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="danger"
            @click="selectResourceType('cameras')"
            >Cameras</b-button
          ><b-button
            class="mr-1"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="success"
            @click="selectResourceType('rdps')"
            >RDPs</b-button
          ><b-button
            variant="warning"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            @click="selectResourceType('qlinks')"
            >QuickLinks</b-button
          >
        </div>
        <b-container>
          <b-row align="center">
            <b-col
              align="center"
              v-for="item in paginatedItems"
              :key="item._id"
              md="3"
            >
              <b-card
                :img-src="require('@/assets/images/pages/content-img-3.jpg')"
                img-alt="Card image cap"
                img-top
                class="border shadow-lg"
                no-body
              >
                <b-card-body class="text-center">
                  <b-card-title>{{ item.name }}</b-card-title>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="danger"
                    :href="item.url"
                    v-if="selectedResourceType === 'cameras'"
                  >
                    Live Stream
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="success"
                    :href="item.url"
                    v-if="selectedResourceType === 'rdps'"
                  >
                    Connect
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="warning"
                    :href="item.url"
                    v-if="selectedResourceType === 'qlinks'"
                  >
                    View
                  </b-button>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <b-pagination
            align="center"
            @change="onPageChanged"
            :total-rows="totalRows"
            :per-page="perPage"
            v-model="currentPage"
          />
        </b-container>
      </div>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BFormRadioGroup,
  BFormRadio,
  BAvatar,
  BFormGroup,
  BCol,
  BCardFooter,
  BCard,
  BCardText,
  BCollapse,
  BDropdown,
  BDropdownGroup,
  BDropdownItem,
  BFormInput,
  BInputGroup,
  BFormCheckbox,
  BButton,
  BModal,
  VBToggle,
  BListGroup,
  BCardBody,
  BCardTitle,
  BInputGroupPrepend,
  BMediaBody,
  BPagination,
  BMediaAside,
  BLink,
  BCardHeader,
  BMedia,
  BListGroupItem,
  BContainer
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { required } from '@validations'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import { MglMap, MglMarker, MglGeojsonLayer } from 'v-mapbox'
import { regionGroup } from '@/services/regionGroup.service'

import MapComponent from '@/components/maps/MapComponent.vue'
import { map } from '@/services/map.service.js'

export default {
  name: 'maps',
  components: {
    AppCollapse,
    AppCollapseItem,
    MapComponent,
    BPagination,
    BCollapse,
    BModal,
    BCardText,
    BMediaAside,
    BMedia,
    BAvatar,
    BLink,
    BButton,
    BContainer,
    BFormRadioGroup,
    BCardHeader,
    BCollapse,
    BMediaBody,
    BCardTitle,
    BCardBody,
    BFormRadio,
    required,
    BCard,
    BRow,
    BCardFooter,
    BCol,
    BDropdown,
    BListGroup,
    BListGroupItem,
    PerfectScrollbar,
    BDropdownGroup,
    BDropdownItem,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    ValidationProvider,
    ValidationObserver,
    MglMarker
  },
  created() {
    this.refreshView()
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true),
      this.$store.commit('appConfig/UPDATE_FOOTER_CONFIG', {
        type: 'hidden'
      })
  },
  destroyed() {
    this.$store.commit(
      'verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED',
      this.isVerticalMenuCollapsed
    ),
      this.$store.commit('appConfig/UPDATE_FOOTER_CONFIG', {
        type: 'default'
      })
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle
  },
  async mounted() {
    this.getAllRegionsWithSites()
    this.refreshView()
  },
  data() {
    return {
      visible: false,
      menuCollapse: {
        isCollapsed: false,
        collapseID: 0,
        height: {}
      },
      openOnHover: this.$parent.hover,
      markerCoordinates: [10, 10],
      allRegionGroups: [],
      allRegionGroupsCopy: [],
      siteData: [],
      regionGroup: [],
      selectedSite: {},
      selectedResourceType: 'cameras',
      paginatedItems: [],
      perPage: 4,
      totalRows: '',
      currentPage: 1,
      searchTerm: '',
      isVerticalMenuCollapsed:
        this.$store.state.verticalMenu.isVerticalMenuCollapsed
    }
  },
  methods: {
    test() {
      document.getElementById('collapse-1').style.height = '70vh'
    },
    expandMe() {
      this.menuCollapse.height = '70vh'
      this.menuCollapse.collaseID += 1
      this.$refs// rerender example id
      .console
        .log(this.$refs)
    },
    ////////////////////////////////////////// FlyTo Controls /////////////////////////////////////////
    handleFlyToMarker(site) {
      window.Bus.instance.$emit('flyToFeature', site)
    },
    ////////////////////////////////////////// Hover Animation Controls ///////////////////////////////
    handleHoverEffectStart(siteID) {
      try {
        document
          .getElementById('pin-base-animated-' + siteID)
          .classList.remove('hidden')
        document.getElementById('pin-' + siteID).classList.add('pin-hover')
      } catch (e) {}
    },
    handleHoverEffectStop(siteID) {
      try {
        document
          .getElementById('pin-base-animated-' + siteID)
          .classList.add('hidden')
        document.getElementById('pin-' + siteID).classList.remove('pin-hover')
      } catch (e) {}
    },

    ////////////////////////////////////////// Search Controls ////////////////////////////////////////
    async search() {
      if (this.searchTerm === '' || this.searchTerm === ' ') {
        this.allRegionGroups = this.allRegionGroupsCopy
        return
      }
      // seach by region group
      // return whole group if searchterm matches group name

      // search by site Name, lsd, siteID, status

      // search by resource name
      let tempResults = []
      for (let k = 0; k < this.allRegionGroupsCopy.length; k++) {
        let group = this.allRegionGroupsCopy[k]
        // search group name
        if (
          group.name.toUpperCase().search(this.searchTerm.toUpperCase()) >= 0
        ) {
          tempResults.push(group)
        } else {
          // if no match in group name search sites
          let tempGroup = JSON.parse(JSON.stringify(group))
          tempGroup.sites = []
          for (let i = 0; i < group.sites.length; i++) {
            group.sites[i]
            if (
              group.sites[i].name
                .toUpperCase()
                .search(this.searchTerm.toUpperCase()) >= 0 ||
              group.sites[i].lsd
                .toUpperCase()
                .search(this.searchTerm.toUpperCase()) >= 0 ||
              group.sites[i].siteID
                .toUpperCase()
                .search(this.searchTerm.toUpperCase()) >= 0 ||
              group.sites[i].status
                .toUpperCase()
                .search(this.searchTerm.toUpperCase()) >= 0
            ) {
              tempGroup.sites.push(group.sites[i])
            } else {
              let slotted = false
              for (let j = 0; j < group.sites[i].cameras.length; j++) {
                if (
                  group.sites[i].cameras[j].name
                    .toUpperCase()
                    .search(this.searchTerm.toUpperCase()) >= 0
                ) {
                  tempGroup.sites.push(group.sites[i])
                  slotted = true
                  j = group.sites[i].cameras.length
                }
              }
              if (slotted === false) {
                for (let j = 0; j < group.sites[i].rdps.length; j++) {
                  if (
                    group.sites[i].rdps[j].name
                      .toUpperCase()
                      .search(this.searchTerm.toUpperCase()) >= 0
                  ) {
                    tempGroup.sites.push(group.sites[i])
                    slotted = true
                    j = group.sites[i].cameras.length
                  }
                }
              }
              if (slotted === false) {
                for (let j = 0; j < group.sites[i].quicklinks.length; j++) {
                  if (
                    group.sites[i].quicklinks[j].name
                      .toUpperCase()
                      .search(this.searchTerm.toUpperCase()) >= 0
                  ) {
                    tempGroup.sites.push(group.sites[i])
                    slotted = true
                    j = group.sites[i].cameras.length
                  }
                }
              }
            }
          }
          if (tempGroup.sites.length > 0) {
            tempResults.push(tempGroup)
          }
        }
      }
      this.allRegionGroups = tempResults
    },

    ////////////////////////////////////////// Modal Controls /////////////////////////////////////////
    selectResourceType(type) {
      this.selectedResourceType = type
      this.paginate(this.perPage, 0)
    },
    paginate(page_size, page_number) {
      let selectedResourceTempData = []
      if (this.selectedResourceType === 'cameras') {
        selectedResourceTempData = this.selectedSite.cameras
        this.totalRows = this.selectedSite.cameras.length
      } else if (this.selectedResourceType === 'rdps') {
        selectedResourceTempData = this.selectedSite.rdps
        this.totalRows = this.selectedSite.rdps.length
      } else {
        selectedResourceTempData = this.selectedSite.quicklinks
        this.totalRows = this.selectedSite.quicklinks.length
      }
      this.paginatedItems = selectedResourceTempData.slice(
        page_number * page_size,
        (page_number + 1) * page_size
      )
    },
    onPageChanged(page) {
      this.paginate(this.perPage, page - 1)
    },
    clearModal() {
      this.selectedSite = {}
    },
    handleOpenSiteModal(site) {
      this.selectedSite = site
      this.paginate(this.perPage, 0)
      this.$bvModal.show('site-modal')
    },
    ///////////////////////////// Helper Functions /////////////////////////////////////////////////////
    regionMaps() {
      return this.regionGroup
    },
    siteMaps() {
      return this.siteData
    },

    async getAllRegionsWithSites() {
      try {
        map.getSites().then((data) => {
          this.allRegionGroups = data.data
          this.allRegionGroupsCopy = data.data
          this.allRegionGroups.forEach((region) => {
            this.regionGroup.push({
              name: region.name,
              geoJSON: region.geoJSON
            })
            region.sites.forEach((site) => {
              this.siteData.push(site)
            })
          })
        })
      } catch (error) {
        window.Bus.instance.$emit('pageDanger', {
          header: 'ERROR',
          detail: error.response.data
            ? error.response.data.error
            : error.response.statusText
        })
      }
    },
    refreshView() {
      // emit event to refresh view
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 600)
    }
  }
}
</script>

<style lang="scss" scoped>
.list-group-item {
  border-radius: 5px;
  border-top: 2px !important;
}

.highlighted {
  background-color: beige;
}

.map-container {
  position: fixed;
  height: 100vh;
  bottom: 0;
  margin: 0;
  left: 0;
  right: 0;
  padding: 0;
  top: 0;
  width: 100%;
}

.bottom {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  width: 100%;
}

/* The Modal Close Button */
.close {
  margin-right: -3px !important;
  margin-top: -3px !important;
}
#site-modal {
  min-width: 50% !important;
}

.mapsearchinput {
  position: floating;
}

.mapContent {
  position: relative;
}

.ps {
  height: 40vh;
}
</style>
